import OktaAuth from '@okta/okta-auth-js';

import { getOktaConfig } from './okta-config';

const oktaConfig = getOktaConfig();

const oktaAuth = new OktaAuth({
  // WidgetOptions defines `authParams` as optional, but it won't be based on the
  // default setup.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  issuer: oktaConfig.authParams.issuer,
  clientId: oktaConfig.clientId,
  redirectUri: window.location.origin + '/login/callback',
  scopes: oktaConfig.authParams?.scopes,
  tokenManager: {
    autoRenew: true,
    expireEarlySeconds: 5 * 60, // To prevent time out of sync problem between client and server
    secure: true,
    storage: window.localStorage,
  },
});

export { oktaAuth };
