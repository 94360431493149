type EncodedPermissions = {
  [labelID: string]: string;
};

type UserResponse = {
  permissions: EncodedPermissions;
  name: string;
};

const bffClient = {
  authenticate: async (token?: string) => {
    const response = await fetch('/api/auth', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token }),
    });

    return response;
  },

  refreshAuthToken: async () => {
    const response = await fetch('/api/refresh_auth', {
      method: 'POST',
      credentials: 'include',
    });
    if (!response.ok) {
      throw new Error('Failed to refresh auth through bff');
    }
  },

  getUser: async () => {
    const response = await fetch('/api/permissions', {
      method: 'GET',
      credentials: 'include',
    });

    const data: UserResponse = await response.json();
    return data;
  },

  logout: async () => {
    const response = await fetch('/api/logout', {
      method: 'POST',
      credentials: 'include',
    });
    if (!response.ok) {
      throw new Error('Failed to delete session data and cookies through bff');
    }
  },
};

export { bffClient };
export type { EncodedPermissions };
