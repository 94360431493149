import { useOktaAuth } from '@okta/okta-react';
import { useMemo } from 'react';

import { bffClient } from '~/modules/auth/lib/bff-client';

import { remotes } from '../registry';
import type { IRemoteContext } from '../types';

type RemoteContextMap = {
  [path: string]: {
    context: IRemoteContext;
    isolateStyles: boolean;
  };
};

const useRemoteContexts = () => {
  const { authState } = useOktaAuth();

  const remoteContexts = useMemo(
    () =>
      remotes.reduce<RemoteContextMap>((map, remote) => {
        map[remote.name] = {
          context: {
            accessToken: authState?.accessToken?.accessToken,
            isAuthenticated: authState?.isAuthenticated,
            prefix: remote.prefix,
            name: remote.name,
            refreshAuthToken: bffClient.refreshAuthToken,
          },
          isolateStyles: remote.isolateStyles ?? false,
        };
        return map;
      }, {}),
    [authState?.accessToken?.accessToken, authState?.isAuthenticated],
  );

  return remoteContexts;
};

export { useRemoteContexts };
export type { RemoteContextMap };
