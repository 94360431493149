import type { IconName } from '~/components/Icon';
import type { Permission } from '~/modules/auth/lib/permissions';

export const SIDEBAR_NAV_ITEMS: {
  to: string;
  icon: IconName;
  label: string;
  permission?: (hasPermission: (permission: Permission) => boolean) => boolean;
}[] = [
  { to: '/', icon: 'list-music', label: 'Releases' },
  {
    to: '/#bulk_upload_modal',
    icon: 'folder-arrow-up',
    label: 'Bulk upload',
  },
  {
    to: '/release-clinic',
    icon: 'truck-medical',
    label: 'Release clinic',
    permission: (hasPermission) =>
      hasPermission('RELEASE_CLINIC.VIEW_INTEGRATION'),
  },
  /*
    NOTE: uncomment this when video claiming is ready to be launched.
    The test for PrimaryLayout will need to be updated to verify the presence of the Video item in the nav.
   */

  // {
  //   to: '/video-management',
  //   icon: 'clapperboard-play',
  //   label: 'Video',
  //   permission: (hasPermission) => hasPermission('VIDEO_CLAIMING.VIEW'),
  // },
  {
    to: '/reports',
    icon: 'chart-mixed',
    label: 'Reports',
    permission: (hasPermission) => hasPermission('REPORT.VIEW'),
  },
  {
    to: '/release/settings/core-details',
    icon: 'file-music',
    label: 'Create release',
    permission: (hasPermission) => hasPermission('RELEASE.CREATE'),
  },
  {
    to: '/platform/settings',
    icon: 'gear',
    label: 'Settings',
    permission: (hasPermission) => hasPermission('PLATFORM.SETTINGS'),
  },
];
