import type React from 'react';

export interface AvatarProps {
  name: string;
}

const getInitials = (name: string) => {
  const initials = name
    .split(' ')
    .map((word) => word[0])
    .join('');
  return initials.toLocaleUpperCase();
};

export const Avatar: React.FC<AvatarProps> = ({ name }) => {
  return (
    <span
      className="
          flex
          items-center
          justify-center
          flex-shrink-0
          text-strong
          font-semibold
          items-center
          h-2xl
          w-2xl
          rounded-full
          bg-tertiary-default
          hover:bg-tertiary-hover
          focus:outline-none
          focus:bg-tertiary-hover
          active:bg-tertiary-pressed
          "
    >
      {getInitials(name)}
    </span>
  );
};
