import type { FC, PropsWithChildren } from 'react';

const RootLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <section className="bg-default text-default min-w-full min-h-dvh flex">
      {children}
    </section>
  );
};

export { RootLayout };
