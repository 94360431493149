import type { DetailedHTMLProps, HTMLAttributes } from 'react';

import { mergeClasses } from '~/lib/mergeClasses';

export type IconName = string; // No need for the fa- prefix here
export type IconVariant = 'solid' | 'regular' | 'light' | 'duotone' | 'brands';
export type IconSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

interface IconProps
  extends Omit<
    DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>,
    'children'
  > {
  type: IconName;
  variant?: IconVariant;
  size?: IconSize;
}

export function Icon({
  size = 'lg',
  type,
  variant = 'solid',
  className,
  ...props
}: IconProps) {
  const fullVariant = `fa-${variant}`;
  const fullName = `fa-${type}`;
  return (
    <i
      className={mergeClasses(
        fullVariant,
        fullName,
        className,
        `w-${size}`,
        `h-${size}`,
      )}
      role="img"
      {...props}
    />
  );
}
