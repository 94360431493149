import * as RadixTooltip from '@radix-ui/react-tooltip';
import type { ReactNode } from 'react';

type TooltipProps = {
  triggerAsChild?: boolean;
  content: ReactNode;
  children: ReactNode;
  side?: RadixTooltip.TooltipContentProps['side'];
  delayDuration?: number;
  enabled?: boolean;
};

const Tooltip = ({
  content,
  children,
  side,
  delayDuration,
  enabled = true,
  triggerAsChild = false,
}: TooltipProps) => (
  <RadixTooltip.Provider>
    <RadixTooltip.Root delayDuration={delayDuration}>
      {enabled ? (
        <RadixTooltip.Trigger asChild={triggerAsChild}>
          {children}
        </RadixTooltip.Trigger>
      ) : (
        <>{children}</>
      )}
      <RadixTooltip.Portal>
        <RadixTooltip.Content
          className="bg-default text-default text-caption-md rounded border border-default animate-fade ml-sm z-50"
          side={side}
        >
          {content}
        </RadixTooltip.Content>
      </RadixTooltip.Portal>
    </RadixTooltip.Root>
  </RadixTooltip.Provider>
);

export { Tooltip };
