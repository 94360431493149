import { useEffect } from 'react';

/**
 * Handles the refresh_auth from the bff's side.
 */
const bffRefreshAuth = async () => {
  const response = await fetch('/api/refresh_auth', {
    method: 'POST',
    credentials: 'include', // Ensures that cookies are included with the request.
  });
  if (!response.ok) {
    throw new Error('Failed to refresh auth through bff');
  }
  console.log('Refreshed auth successfully');
};

/**
 * /auth/refresh
 *
 * Page dedicated to refresh auth.
 */
export const RefreshAuth = () => {
  useEffect(() => {
    const handleRefreshAuth = async () => {
      try {
        await bffRefreshAuth();
      } catch (error) {
        console.error('Refresh auth error:', error);
      }
    };

    handleRefreshAuth();
  }, []);

  return null;
};
