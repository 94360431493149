import type { DetailedHTMLProps, HTMLAttributes } from 'react';

import { mergeClasses } from '~/lib/mergeClasses';

interface SeparatorProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLHRElement>, HTMLHRElement> {}

export function Separator({ className, ...props }: SeparatorProps) {
  return (
    <hr
      className={mergeClasses('border-default border-opacity-25', className)}
      {...props}
    />
  );
}
