import { useOktaAuth } from '@okta/okta-react';
import {
  createContext,
  type PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { bffClient } from '../lib/bff-client';

const WmgSessionContext = createContext(false);

const WmgSessionProvider = ({ children }: PropsWithChildren) => {
  const { authState } = useOktaAuth();
  const [hasWmgSession, setHasWmgSession] = useState(false);

  const fetchWmgSession = useCallback(
    async (token?: string) => {
      if (token && !hasWmgSession) {
        try {
          await bffClient.authenticate(token);
          setHasWmgSession(true);
        } catch {
          setHasWmgSession(false);
        }
      } else {
        setHasWmgSession(false);
      }
    },
    [hasWmgSession],
  );

  useEffect(() => {
    const token = authState?.accessToken?.accessToken;
    fetchWmgSession(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState?.accessToken?.accessToken]);

  return (
    <WmgSessionContext.Provider value={hasWmgSession}>
      {children}
    </WmgSessionContext.Provider>
  );
};

export { WmgSessionContext, WmgSessionProvider };
