import { AdaLogo } from '~/components/AdaLogo';

const BrandLogo = () => {
  return (
    <div className="absolute px-2xl mt-xl top-0 left-0 flex justify-center w-full md:block">
      <AdaLogo className="text-white h-xl" />
    </div>
  );
};

export { BrandLogo };
