import { Outlet } from 'react-router-dom';

import { AdaLogo } from '~/components/AdaLogo';
import { Avatar } from '~/components/Avatar';
import { Popover } from '~/components/Popover';
import { SIDEBAR_NAV_ITEMS } from '~/lib/nav';
import { useUser } from '~/modules/auth/hooks/useUser';

import { SidebarMenuItem } from '../Sidebar/parts/SidebarMenuItem';
import { SidebarPopoverContent } from '../Sidebar/parts/SidebarPopoverContent';
import { Sidebar } from '../Sidebar/Sidebar';

type Props = {
  showNavigation?: boolean;
};

const PrimaryLayout = ({ showNavigation }: Props) => {
  const { hasPermission, username } = useUser();

  return (
    <>
      {showNavigation && (
        <Sidebar>
          <div className="flex flex-shrink-0 justify-center items-center w-full pt-lg px-md pb-md">
            <AdaLogo className="w-2xl h-2xl text-primary-default" />
          </div>
          <div className="h-full flex flex-col gap-lg px-md pt-sm">
            {SIDEBAR_NAV_ITEMS.map(
              ({ to, icon, label, permission }) =>
                (!permission || permission(hasPermission)) && (
                  <SidebarMenuItem key={label} to={to} icon={icon}>
                    {label}
                  </SidebarMenuItem>
                ),
            )}
          </div>
          <Popover content={<SidebarPopoverContent name={username} />}>
            <div className="px-md pb-lg">
              <Avatar name={username} />
            </div>
          </Popover>
        </Sidebar>
      )}
      <Outlet />
    </>
  );
};

export { PrimaryLayout };
