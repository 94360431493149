import * as RadixPopover from '@radix-ui/react-popover';
import type { ReactNode } from 'react';

type PopoverProps = {
  content: ReactNode;
  children: ReactNode;
  disabled?: boolean;
};

export const Popover = ({ content, children, disabled }: PopoverProps) => (
  <RadixPopover.Root>
    <RadixPopover.Anchor />
    <RadixPopover.Trigger disabled={disabled}>{children}</RadixPopover.Trigger>
    <RadixPopover.Portal>
      <RadixPopover.Content side="right" sideOffset={5} className="z-50">
        {content}
      </RadixPopover.Content>
    </RadixPopover.Portal>
  </RadixPopover.Root>
);
