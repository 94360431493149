import type React from 'react';

import { Separator } from '~/components/Separator';
import { HoverIconItem } from '~/modules/navigation/components/Sidebar/parts/HoverIconItem';

interface SidebarPopoverContentProps {
  name: string;
}

export const SidebarPopoverContent: React.FC<SidebarPopoverContentProps> = ({
  name,
}) => {
  return (
    <div
      className="
          gap-md
          border-solid
          border-default
          border-opacity-10
          flex
          flex-col
          text-white
          justify-between
          content-center
          p-lg
          w-[15rem]
          h-auto
          rounded
          bg-level-02
          mb-lg
          animate-fade
          "
    >
      <p className="text-headline-sm">{name}</p>
      <Separator className="w-full m-0" />
      <HoverIconItem icon="user" text="Profile" to="/my-profile" />
      <HoverIconItem
        icon="circle-question"
        text="Support"
        to="/support/ada_partner_hub_EN.pdf"
        target="_blank"
        rel="noopener noreferrer"
      />
      <HoverIconItem
        icon="comment-lines"
        text="Feedback"
        to="#feedback_modal"
      />
      <Separator className="w-full m-0" />
      <HoverIconItem icon="right-from-bracket" text="Sign out" to="/logout" />
    </div>
  );
};
