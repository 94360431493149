import { toRelativeUrl } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { type FC, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { BrandLogo } from '~/modules/branding/components/BrandLogo';
import { ErrorPage } from '~/modules/errors/components/ErrorPage';

import { isKnownPath } from '../lib/utils';

/**
 * Denies access to Outlet and redirect in case of unauthenticated users.
 */
export const SecureRoute: FC = () => {
  const { oktaAuth, authState } = useOktaAuth();

  useEffect(() => {
    // When Okta finished loading, but user isn't authenticated
    if (authState && !authState.isAuthenticated) {
      const originalUri = toRelativeUrl(
        window.location.href,
        window.location.origin,
      );

      oktaAuth.setOriginalUri(originalUri);
      oktaAuth.signInWithRedirect();
    }
  }, [oktaAuth, authState]);

  if (!authState || !authState?.isAuthenticated) {
    return (
      <>
        <BrandLogo />
        <div className="tailwind-preflight bg-brand bg-no-repeat min-h-screen flex justify-center md:justify-start items-center md:px-2xl" />
      </>
    );
  }

  if (!isKnownPath(window.location.pathname)) {
    return <ErrorPage />;
  }

  return <Outlet />;
};
