import { useCallback, useEffect, useState } from 'react';

import { bffClient } from '../lib/bff-client';
import type { Permission } from '../lib/permissions';
import { parsePermissions } from '../lib/permissions';
import { useHasWmgSession } from './useHasWmgSession';

const useUser = () => {
  const hasWmgSession = useHasWmgSession();
  const [permissions, setPermissions] = useState<{
    [entity: string]: Permission[];
  }>({});
  const [username, setUsername] = useState('');

  const fetchPermissions = useCallback(async () => {
    const user = await bffClient.getUser();
    setPermissions(parsePermissions(user.permissions));
    setUsername(user.name);
  }, []);

  useEffect(() => {
    if (hasWmgSession) {
      fetchPermissions();
    }
  }, [fetchPermissions, hasWmgSession]);

  // permissions that aren't scoped to any entity (label/release/etc.) will be
  // present in each entity's permissions array, so we can just check the first one
  const hasPermission = useCallback(
    (permission: Permission) => {
      const firstEntity = Object.keys(permissions)[0];
      return permissions[firstEntity]?.includes(permission) ?? false;
    },
    [permissions],
  );

  return {
    username,
    permissions,
    hasPermission,
  };
};

export { useUser };
