import type { Config } from 'tailwind-merge';
import { extendTailwindMerge } from 'tailwind-merge';

// type Config = Parameters<typeof extendTailwindMerge>[0];

const TSHIRT_SIZES = ['sm', 'md', 'lg'] as const;

export const DEFAULT_MERGE_CONFIG: Partial<Config<never, never>> = {
  theme: {
    spacing: [
      'px',
      '0',
      '2xs',
      'xs',
      'sm',
      'md',
      'lg',
      'xl',
      '2xl',
      '3xl',
      '4xl',
    ],
  },
  classGroups: {
    'font-size': {
      // This could be done via tailwind config import, but there is a problem with module resolution and typing
      text: [
        ...TSHIRT_SIZES.map((size) => `display-${size}`),
        ...TSHIRT_SIZES.map((size) => `headline-${size}`),
        ...TSHIRT_SIZES.map((size) => `body-${size}`),
        ...TSHIRT_SIZES.map((size) => `caption-${size}`),
      ],
    },
  },
};

export const mergeClasses = extendTailwindMerge({
  extend: DEFAULT_MERGE_CONFIG,
});
