import * as amplitude from '@amplitude/analytics-browser';

const initAmplitude = (apiKey?: string) => {
  if (!apiKey) {
    return;
  }

  const instance = amplitude.createInstance();

  instance.init(apiKey, {
    defaultTracking: true,
    instanceName: 'container',
  });

  return instance;
};

export { initAmplitude };
