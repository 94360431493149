/**
 * Environment variables.
 *
 * Never make this dynamic: Vite needs `import.meta.env.*` syntax to build envs
 * statically.
 *
 * Ignoring test coverage for these variables, as there's no functional value in
 * gating coverage here.
 */
/* istanbul ignore next */
export const variables = {
  VITE_OKTA_CLIENT_ID: import.meta.env.VITE_OKTA_CLIENT_ID,
  VITE_OKTA_DOMAIN: import.meta.env.VITE_OKTA_DOMAIN,
  VITE_OKTA_ISSUER: import.meta.env.VITE_OKTA_ISSUER,
  VITE_OKTA_HELP_URL: import.meta.env.VITE_OKTA_HELP_URL,
  BASE_URL: import.meta.env.BASE_URL,
  VITE_NR_ACCOUNT_ID: import.meta.env.VITE_NR_ACCOUNT_ID,
  VITE_NR_BEACON: import.meta.env.VITE_NR_BEACON,
  VITE_NR_LICENSE_KEY: import.meta.env.VITE_NR_LICENSE_KEY,
  VITE_NR_AGENT_ID: import.meta.env.VITE_NR_AGENT_ID,
  VITE_NR_APPLICATION_ID: import.meta.env.VITE_NR_APPLICATION_ID,
  VITE_NR_TRUST_KEY: import.meta.env.VITE_NR_TRUST_KEY,
  VITE_AMPLITUDE_API_KEY: import.meta.env.VITE_AMPLITUDE_API_KEY,
  VITE_USE_CONTAINER_NAVIGATION: import.meta.env.VITE_USE_CONTAINER_NAVIGATION,
};
