import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import { ErrorPage } from '~/modules/errors/components/ErrorPage';

import { ErrorBoundary } from '../errors/components/ErrorBoundary';
import { RemotesStatus } from './components/RemotesStatus';
import { useRemoteContexts } from './hooks/use-remote-contexts';
import { remotes } from './registry';

/**
 * Uniform Router.
 */
const RemoteRouter = () => {
  const remoteContexts = useRemoteContexts();

  return (
    <ErrorBoundary>
      <Routes>
        {remotes.map((remote) => (
          <Route
            key={remote.name}
            path={remote.path[0]}
            errorElement={<ErrorPage />}
            element={
              <Suspense>
                <div
                  className={[
                    'w-full',
                    remoteContexts[remote.name].isolateStyles
                      ? 'no-preflight'
                      : '',
                  ].join(' ')}
                >
                  <remote.root ctx={remoteContexts[remote.name].context} />
                </div>
              </Suspense>
            }
          />
        ))}

        <Route path="_dev/remotes-status" element={<RemotesStatus />} />
      </Routes>
    </ErrorBoundary>
  );
};

export { RemoteRouter };
