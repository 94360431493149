import { Suspense } from 'react';
import { Helmet } from 'react-helmet-async';

import { ErrorBoundary } from '~/modules/errors/components/ErrorBoundary';

import { remotes } from '../registry';

const RemotesStatus = () => {
  return (
    <section>
      <Helmet>
        <title>Dev: status of remote apps</title>
      </Helmet>
      <h1>Remote apps: status</h1>
      <table>
        <thead>
          <tr>
            <th>Remote app</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {remotes.map((remote) => (
            <tr key={remote.name}>
              <td>{remote.name}</td>
              <td>
                <ErrorBoundary
                  fallback={
                    <span className="bg-error-default rounded">Down</span>
                  }
                >
                  <Suspense>
                    <span className="bg-success-default rounded">
                      Operational
                    </span>
                    <remote.status />
                  </Suspense>
                </ErrorBoundary>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
};

export { RemotesStatus };
