import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import { RootLayout } from '~/components/RootLayout';
import { AuthenticationProvider } from '~/modules/auth/components/AuthenticationProvider';
import { WmgSessionProvider } from '~/modules/auth/contexts';

import { Router } from './router';

export function App() {
  return (
    <HelmetProvider>
      <RootLayout>
        <AuthenticationProvider>
          <WmgSessionProvider>
            <BrowserRouter>
              <Router />
            </BrowserRouter>
          </WmgSessionProvider>
        </AuthenticationProvider>
      </RootLayout>
    </HelmetProvider>
  );
}
