import type { Tokens } from '@okta/okta-auth-js';
import { LoginCallback, useOktaAuth } from '@okta/okta-react';
import type { WidgetOptions } from '@okta/okta-signin-widget';
import { useCallback, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { AuthLayout, OktaSignInWidget } from '~/modules/auth/components';
import { getOktaConfig } from '~/modules/auth/lib/okta-config.ts';

function getConfigOverrides() {
  const loginUrl = new URLSearchParams(window.location.search);
  const recovery = loginUrl.get('recovery') ?? undefined;
  const activate = loginUrl.get('activate') ?? undefined;

  const config: Partial<WidgetOptions> = {
    recoveryToken: recovery ?? activate,
  };

  if (activate) {
    config.i18n = {
      en: {
        'password.reset.title.generic': 'Set your password',
        'password.reset': 'Create My Account',
        'factor.push.description': 'Set up Okta Verify to continue.',
        'enroll.choices.submit.configure': 'Continue',
      },
    };
  }

  return config;
}

let sessionChecked = false;

const useCheckSession = () => {
  const { oktaAuth } = useOktaAuth();

  const checkSession = useCallback(async () => {
    const tokenResponse = await oktaAuth.token.getWithoutPrompt({
      responseType: ['token'],
    });

    if (tokenResponse) {
      return await oktaAuth.handleLoginRedirect(tokenResponse.tokens);
    }
  }, [oktaAuth]);

  useEffect(() => {
    if (sessionChecked) {
      return;
    }
    checkSession();
    sessionChecked = true;
  }, [checkSession]);
};

/**
 * Uniform Router.
 */
const AuthRouter = () => {
  const config = getOktaConfig(getConfigOverrides());
  const { oktaAuth, authState } = useOktaAuth();

  const onSuccess = (tokens: Tokens) => {
    oktaAuth.handleLoginRedirect(tokens);
  };

  const onError = (...args: unknown[]) => {
    console.log('Sign in error:', args);
  };

  useCheckSession();

  if (authState?.isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <AuthLayout>
      <Routes>
        <Route path="/callback" element={<LoginCallback />} />
        <Route
          key="Home"
          path="/welcome"
          element={
            <OktaSignInWidget
              config={config}
              onError={onError}
              onSuccess={onSuccess}
            />
          }
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </AuthLayout>
  );
};

export { AuthRouter };
