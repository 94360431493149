import { Route, Routes } from 'react-router-dom';

import { env } from '~/lib/env';
import { Logout } from '~/modules/auth/components/Logout.tsx';
import { RefreshAuth } from '~/modules/auth/components/RefreshAuth.tsx';
import { SecureRoute } from '~/modules/auth/components/SecureRoute';
import { AuthRouter } from '~/modules/auth/router';
import { RemoteRouter } from '~/modules/remotes/router';

import { PrimaryLayout } from './modules/navigation/components/PrimaryLayout';

/**
 * Top-level router combining module-level routers
 */
const Router = () => {
  return (
    <Routes>
      <Route path="login/*" element={<AuthRouter />} />
      <Route path="*" element={<SecureRoute />}>
        <Route path="logout" element={<Logout />} />
        <Route path="auth/refresh" element={<RefreshAuth />} />
        <Route
          path="*"
          element={
            <PrimaryLayout
              showNavigation={
                env('VITE_USE_CONTAINER_NAVIGATION', false) === 'true'
              }
            />
          }
        >
          <Route path="*" element={<RemoteRouter />} />
        </Route>
      </Route>
    </Routes>
  );
};

export { Router };
