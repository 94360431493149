import type { EncodedPermissions } from './bff-client';

/*
 * Bit sequence of permissions defined on the backend here:
 * https://github.com/wmgtech/wmg2-dataplatform/blob/main/authapi/sdk/authapi_sdk/permissions_schema.json
 */
const allPermissions = [
  'LABEL.CREATE',
  'LABEL.DELETE',
  'LABEL.EDIT',
  'LABEL_GROUP.ASSIGN_USER',
  'LABEL_GROUP.CREATE',
  'LABEL_GROUP.DELETE',
  'LABEL_GROUP.EDIT',
  'PLATFORM.GOD_MODE',
  'PLATFORM.SETTINGS',
  'RELEASE.ADD_RELEASE_COMMENT',
  'RELEASE.APPROVE',
  'RELEASE.CLAIMING_EDIT',
  'RELEASE.CLAIMING_VIEW',
  'RELEASE.CREATE',
  'RELEASE.DATA_SYNC_FLAG',
  'RELEASE.DELETE',
  'RELEASE.DISTRIBUTE',
  'RELEASE.EDIT',
  'RELEASE.MANAGE_AUDIO_QC_FEEDBACK',
  'RELEASE.REJECT',
  'RELEASE.REQUEST_AUDIO_QC',
  'RELEASE.REQUEST_EDIT',
  'RELEASE.REQUEST_EDIT_VIDEO',
  'RELEASE.RETRACT',
  'RELEASE.SHARE',
  'RELEASE.SUBMIT',
  'RELEASE.TAKE_DOWN',
  'RELEASE.VIEW',
  'RELEASE.VIEW_APPROVALS',
  'RELEASE_CLINIC.RETRY_INTEGRATION',
  'RELEASE_CLINIC.VIEW_INTEGRATION',
  'REPORT.CREATE',
  'REPORT.DELETE',
  'REPORT.EDIT',
  'REPORT.EXPORT',
  'REPORT.SHARE',
  'REPORT.VIEW',
  'ROLE.EDIT',
  'TRACK.CREATE',
  'TRACK.DELETE',
  'TRACK.DOWNLOAD',
  'TRACK.EDIT',
  'UI.SWITCH_DESIGN',
  'USER.ASSIGN_ROLE',
  'USER.CREATE',
  'USER.DELETE',
  'USER.EDIT',
  'USER.MANAGE_STATUS',
  'VIDEO_CLAIMING.ADMIN',
  'VIDEO_CLAIMING.VIEW',
  'VIDEO_CLAIMING.CREATE',
  'VIDEO_CLAIMING.EDIT',
  'VIDEO_CLAIMING.DELETE',
] as const;

type Permission = (typeof allPermissions)[number];

type EntityPermissions = Record<string, Permission[]>;

const parsePermissionString = (permissionString: string) => {
  const binaryString = atob(permissionString)
    .split('')
    .map((char) => char.charCodeAt(0).toString(2).padStart(8, '0'))
    .join('');

  const permissions: Permission[] = [];

  for (let i = 0; i < binaryString.length; i++) {
    if (binaryString[i] === '1') {
      permissions.push(allPermissions[i]);
    }
  }

  return permissions;
};

const parsePermissions = (encodedPermissions: EncodedPermissions) => {
  return Object.fromEntries(
    Object.entries(encodedPermissions).map(([entityID, permissionString]) => [
      entityID,
      parsePermissionString(permissionString),
    ]),
  );
};

export { allPermissions, parsePermissions };
export type { EntityPermissions, Permission };
