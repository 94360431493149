import type React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { Icon } from '~/components/Icon';

interface HoverIconItemProps {
  icon: string;
  text: string;
  to: string;
  target?: string;
  rel?: string;
}

export const HoverIconItem: React.FC<HoverIconItemProps> = ({
  icon,
  text,
  to,
  target,
  rel,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Link
      to={to}
      target={target}
      rel={rel}
      className="text-default no-underline text-caption-md flex items-center cursor-pointer"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Icon
        type={icon}
        variant={isHovered ? 'solid' : 'regular'}
        className="pr-sm"
      />
      <span className="hover:underline">{text}</span>
    </Link>
  );
};
